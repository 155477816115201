<template>
	<div class="deviceslist d-flex flex-column pa-0" style="width: 100%">
		<div class="mt-1 subheader" v-if="isDevices">
			<!-- Selection controls: All & Nothing -->
			<!-- <span class="selector" @click="changeAll(true)">{{ $t('panels.configpannel.all') }}</span>
			<span class="ml-2 mr-2">|</span>
			<span class="selector" @click="changeAll(false)">{{ $t('panels.configpannel.none') }}</span> -->
		</div>
		<div v-if="!isSuperAdmin">
			<!-- Device List by Datasourcetype -->
			<div v-for="(item, i) in devicesbyAreaType" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group color="black" class="px-0" v-on:click="groupByArea(item.areatypedev)">
						<template #activator>
							<v-list-item-title class="high fw-bold fs-regular px-0">{{ item.pmareatypename }}</v-list-item-title>
						</template>
						<div v-for="(item, i) in devicesbyArea" :key="i">
							<v-list flat dense style="background: transparent" class="pt-0 ml-2">
								<v-list-group class="px-0" v-on:click="groupByDatasourcetype(item.areadev, i)" v-model="isOpen[i]">
									<template #activator>
										<v-list-item-title class="high fw-bold fs-regular px-0">{{ item.pmareaname }}</v-list-item-title>
									</template>
									<div v-for="(item, i) in devicesByDtsType" :key="i">
										<v-list flat dense style="background: transparent" class="pt-0 ml-2">
											<v-list-group color="black" class="px-0 border-b">
												<template #activator>
													<v-img
														max-height="20"
														max-width="20"
														contain
														:src="appProperties.urlbase + item.datasourcetypeicon"
														class="datasourcetype-icon rounded mr-4"
													></v-img>
													<v-list-item-title class="high fw-bold fs-regular text-capitalize ml-n2 px-0">
														{{ item.pmdatasourcetypename }}
													</v-list-item-title>
												</template>

												<div v-for="(device, i) in item.deviceGroup" :key="i">
													<device-list-title
														:value="true"
														:active="isActive(device.identifier)"
														@update:active="(value) => setActive(device.identifier, value)"
														:title="device.identifier"
														:disabledItem="!device.active"
														:noPadding="true"
														:device="device"
														border-bottom
													>
													</device-list-title>
												</div>
											</v-list-group>
										</v-list>
									</div>
								</v-list-group>
							</v-list>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
		<div v-else>
			<!-- Device List by Organization 45 a 51, copiar para area i areaname-->
			<div v-for="(item, i) in devicesByOrganization" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group class="px-0" v-on:click="groupByAreaType(item.orgdev)">
						<template #activator>
							<v-list-item-title class="high fw-bold fs-regular px-0">{{ item.organizationname }}</v-list-item-title>
						</template>
						<div v-for="(item, j) in devicesbyAreaType" :key="j">
							<v-list flat dense style="background: transparent" class="pt-0 ml-2">
								<v-list-group class="px-0" v-on:click="groupByArea(item.areatypedev)">
									<template #activator>
										<v-list-item-title class="high fw-bold fs-regular px-0">{{ item.pmareatypename }}</v-list-item-title>
									</template>
									<div v-for="(item, i) in devicesbyArea" :key="i">
										<v-list flat dense style="background: transparent" class="pt-0 ml-2">
											<v-list-group class="px-0" v-on:click="groupByDatasourcetype(item.areadev, i)" v-model="isOpen[i]">
												<template #activator>
													<v-list-item-title class="high fw-bold fs-regular color-black px-0">{{
														item.pmareaname
													}}</v-list-item-title>
												</template>
												<div v-for="(item, i) in devicesByDtsType" :key="i">
													<v-list flat dense style="background: transparent" class="pt-0 ml-2">
														<v-list-group class="px-0">
															<template #activator>
																<v-img
																	max-height="20"
																	max-width="20"
																	contain
																	:src="appProperties.urlbase + item.datasourcetypeicon"
																	class="datasourcetype-icon rounded mr-4"
																></v-img>
																<v-list-item-title class="high fw-bold fs-regular text-capitalize ml-n2 px-0">{{
																	item.pmdatasourcetypename
																}}</v-list-item-title>
															</template>

															<div v-for="(device, i) in item.deviceGroup" :key="i">
																<device-list-title
																	:value="true"
																	:active="isActive(device.identifier)"
																	@update:active="(value) => setActive(device.identifier, value)"
																	:title="device.identifier"
																	:disabledItem="!device.active"
																	:noPadding="true"
																	:device="device"
																	border-bottom
																>
																</device-list-title>
															</div>
														</v-list-group>
													</v-list>
												</div>
											</v-list-group>
										</v-list>
									</div>
								</v-list-group>
							</v-list>
						</div>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import DeviceListTitle from './subpanels/common/DeviceListTitle';
import { isSuperAdmin, sortKey } from '@/api/common';

export default {
	name: 'DevicesList',
	components: {
		DeviceListTitle
	},
	data() {
		return {
			allDevices: [],
			//satelliteImagesGroupedByType: [],
			devicesByDtsType: [],
			devicesByOrganization: [],
			devicesbyArea: [],
			devicesbyAreaType: [],
			profile: '',
			isOpen: [],
			isSuperAdmin: false
		};
	},
	computed: {
		isDevices() {
			return Object.keys(this.devices).length > 0;
		},

		devices() {
			/* return Object.assign(
				{},
				Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL)
			); */
			return this.$store.getters.getDevices;
		},
		appProperties() {
			return this.$store.getters.getApplication;
		},
		visibleDatasources() {
			return Object.values(this.$store.getters.layers).map((layer) => {
				return layer.pmdatasourcetypeid;
			});
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.afterGetData();
		console.info('== Devices getter ==', this.devices);
	},
	/*created() {

		this.profile = session.profile;
		if (this.isSuperAdmin) {
			this.afterGetData();
		}
	},*/

	methods: {
		toggleList(index) {
			for (let i = 0; i < this.devicesbyArea.length; i++) {
				this.isOpen[i] = false;
			}
			this.isOpen[index] = !this.isOpen[index];
		},
		isActive(deviceId) {
			return this.$store.state.mappanel.devices[deviceId]['active'];
		},

		toggleAll(device, value) {
			ol2map.setVisibility(device, value);
			this.$store.state.mappanel.devices[device]['active'] = value;
		},

		setActive(device, active) {
			ol2map.setVisibility(device, active);
			this.$store.state.mappanel.devices[device]['active'] = active;
		},
		async afterGetData() {
			let devicesArray = Object.entries(this.devices).map(([name, obj]) => ({ name, ...obj }));
			devicesArray.sort((a, b) => {
				const [textA, numA] = sortKey(a.identifier);
				const [textB, numB] = sortKey(b.identifier);

				// First, compare the text parts
				if (textA < textB) return -1;
				if (textA > textB) return 1;

				// If the text parts are equal, compare the numeric parts
				return numA - numB;
			});
			console.log('devicesArray', devicesArray);
			this.allDevices = devicesArray.filter((device) => this.visibleDatasources.includes(device.pmdatasourcetypeid));

			this.groupByAreaType(this.allDevices, -1);
			if (this.isSuperAdmin) {
				this.groupByOrganization(this.allDevices);
			}
		},
		groupByArea(dataset) {
			const groupedArray = Object.values(
				dataset.reduce((r, { pmareaname, ...rest }) => {
					if (!r[pmareaname]) {
						r[pmareaname] = { pmareaname, areadev: [rest] };
					} else {
						r[pmareaname].areadev.push(rest);
					}
					return r;
				}, {})
			);
			this.devicesbyArea = groupedArray;
		},
		groupByAreaType(dataset) {
			const groupedArray = Object.values(
				dataset.reduce((r, { pmareatypename, ...rest }) => {
					if (!r[pmareatypename]) {
						r[pmareatypename] = { pmareatypename, areatypedev: [rest] };
					} else {
						r[pmareatypename].areatypedev.push(rest);
					}
					return r;
				}, {})
			);
			this.devicesbyAreaType = groupedArray;
		},

		groupByDatasourcetype(dataset, index) {
			this.toggleList(index);
			const groupedArray = Object.values(
				dataset.reduce((r, { pmdatasourcetypename, datasourcetypeicon, ...rest }) => {
					if (!r[pmdatasourcetypename])
						r[pmdatasourcetypename] = {
							pmdatasourcetypename,
							datasourcetypeicon,
							deviceGroup: [rest]
						};
					else r[pmdatasourcetypename].deviceGroup.push(rest);
					return r;
				}, {})
			);
			this.devicesByDtsType = groupedArray;
		},

		groupByOrganization(dataset) {
			const groupedArray = Object.values(
				dataset.reduce((r, { organizationname, ...rest }) => {
					if (!r[organizationname]) {
						r[organizationname] = { organizationname, orgdev: [rest] };
					} else {
						r[organizationname].orgdev.push(rest);
					}
					return r;
				}, {})
			);
			this.devicesByOrganization = groupedArray;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.deviceslist {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}

	& .v-list-item {
		padding: 0px !important;
	}
}
</style>
