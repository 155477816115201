<template>
	<v-card class="width-100 height-100 overflow-hidden datasource-index" style="border-top-right-radius: 0px; border-top-left-radius: 0px">
		<v-tabs v-model="selectedTabDatasource" align-tabs="title" height="48">
			<v-tab v-for="item in datasourceTabs" :key="item.value" :ref="item.value" :href="'#' + item.value"> {{ $t(item.label) }} </v-tab>
		</v-tabs>

		<v-card-text class="pa-0 overflow-hidden ma-0" :style="panelStyle">
			<v-window v-model="selectedTabDatasource">
				<v-window-item v-for="item in datasourceTabs" :key="item.value" :value="item.value">
					<v-card class="pa-0" flat>
						<component :is="item.component" />
					</v-card>
				</v-window-item>
			</v-window>
		</v-card-text>
	</v-card>
</template>

<script>
import DatasourceHistoric from './DatasourceHistoric.vue';
import DatasourceRealTime from './DatasourceRealTime.vue';

//import NotificationHistoric from '../notification/NotificationHistoric.vue';
//import NotificationActive from '../notification/NotificationActive.vue';

import { CARD_CONTAINER_HEIGHT } from '@/lib/variables/panels';
//import { obtainPanelRealTimeData, obtainIndexName, obtainPanelHistoricFirstData, obtainPanelPredictionsDateRange } from '@/api/common';

import constants from '@/utils/constants';

export default {
	name: 'DatasourceIndex',
	components: {
		DatasourceHistoric,
		DatasourceRealTime
	},
	props: {},
	data() {
		return {
			appearance: {
				height: '100%',
				width: '100%',
				overflow: 'hidden'
				//position: 'absolute'
			},
			satelliteType: constants.DATASOURCETYPE_SATELLITE,
			satelliteModel: constants.SATELLITE_MODEL,
			panelStyle: {
				height: CARD_CONTAINER_HEIGHT,
				overflow: 'scroll'
			},
			tab: null,
			showPredictionsXai: false,
			tabIndex: null,
			datasourceTabs: [
				{ label: 'panels.device.realTime', component: 'DatasourceRealTime', value: constants.FEATURE_TABS.REAL_TIME },
				{ label: 'panels.device.historic', component: 'DatasourceHistoric', value: constants.FEATURE_TABS.HISTORIC }
			],
			formAdvanced: {
				startDate: null,
				endDate: null,
				selectedOptions: [],
				option: null,
				chartData: null,
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'ADVANCED_CHART_COMPARE_PROPERTIES'
				}
			}
		};
	},
	computed: {
		realTimeElasticData: {
			get() {
				return this.$store.getters.getDatasourceRealTimeData;
			},
			set(value) {
				this.$store.commit('setDatasourceRealTimeData', value);
			}
		},
		selectedTabDatasource: {
			get() {
				return this.$store.getters.getSelectedTabDatasource;
			},
			set(value) {
				this.$store.commit('setSelectedTabDatasource', value);
			}
		},
		hasActiveNotifications() {
			return this.featureActiveNotifications || this.calledFromNotification;
		},
		featureActiveNotifications() {
			if (this.$store.getters.getNotifications && this.$store.getters.getNotifications.length > 0) {
				return this.$store.getters.getNotifications;
			} else {
				return false;
			}
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	},
	watch: {
		tab: function () {
			console.log('device tab changed', this.tab);
		},
		selectedTabDatasource: function () {
			console.log('selected tab changed', this.selectedTabDatasource);
		}
		/* deviceObject: {
			handler(newValue, oldValue) {
				console.debug('deviceObject watcher', oldValue.code, newValue.code);
				if (newValue && newValue.code != oldValue.code) {
					let indexName = obtainIndexName(this.deviceObject);
					if (indexName) obtainPanelRealTimeData(this.deviceObject.code, this.deviceObject.pmdatasourcetypeid, indexName, this.$store);
				}
			},
			deep: true
		} */
	},
	beforeCreate() {
		this.$vuetify.lang.current = this.$store.getters.getSession.language;
	},
	mounted() {
		console.log('mounted device index', this.displayMode);
		this.$store.commit('setFormAdvancedObject', this.formAdvanced);
		this.$store.commit('setAdvancedHistoricElasticData', null);
		this.$store.commit('setAdvancedDatasourceElasticData', null);

		console.log('mounted device ', this.featureActiveNotifications, this.datasourceTabs);
	},
	updated() {},
	beforeDestroy() {
		console.warn('beforeDestroy device');
	},
	methods: {}
};
</script>

<style lang="postcss">
.v-tab {
	max-width: 400px;
}
</style>
