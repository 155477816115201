<template>
	<div class="pt-0 w-100 historic-datasource-chart">
		<v-form v-model="pollutantsForm" ref="form" style="max-height: 124px; overflow: hidden">
			<v-row no-gutters class="px-3">
				<v-col cols="12" sm="3">
					<v-autocomplete
						:label="$t('form.pmdatasourcetype')"
						toplabel
						clearable
						required
						v-model="selectedDatasource"
						:items="datasources"
						item-text="name"
						return-object
						hide-details
						class="mt-2"
						:disabled="loadingAnimation"
						@input="inputDatasource"
					>
						<!-- <template v-slot:selection="{ item }">
							<v-chip class="v-chip--select v-chip v-chip--clickable v-chip--no-color theme--light v-size--small">
								<span class="fs-xs">{{ item.name }}</span>
							</v-chip>
						</template> -->
					</v-autocomplete>
				</v-col>
				<v-col cols="12" sm="3">
					<v-autocomplete
						:label="$t('common.devices')"
						toplabel
						clearable
						required
						v-model="selectedDevices"
						:items="devicesOptions"
						:disabled="disabledDevices || loadingAnimation"
						multiple
						item-text="name"
						return-object
						hide-details
						class="mt-2 px-1"
						@change="dynamicInput"
					>
						<template v-slot:prepend-item>
							<v-list-item
								ripple
								@mousedown.prevent
								@click="toggleSelection('selectedDevices', 'devicesOptions', selectedDevices.length == devicesOptions.length)"
							>
								<!-- @click="toggleSelection('selectedDevices', 'devicesOptions', selectedDevices.length == devicesOptions.length)" -->
								<v-list-item-action>
									<v-icon :color="selectedDevices.length > 0 ? primaryColor : ''">
										{{ icon(selectedDevices.length, devicesOptions.length) }}
									</v-icon>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>
										{{
											selectedDevices.length == devicesOptions.length
												? $t('panels.device.unselect-all')
												: $t('panels.device.select-all')
										}}
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider class="mt-2"></v-divider>
						</template>
						<template v-slot:selection="{ item, index }">
							<v-chip v-if="index == 0" small>
								<span>{{ item.name }}</span>
							</v-chip>
							<span v-if="index == 1" class="grey--text text-caption"> (+{{ selectedDevices.length - 1 }} others) </span>
						</template>
					</v-autocomplete>
				</v-col>
				<v-col cols="12" sm="3">
					<v-autocomplete
						:label="$t('aqi.pollutant')"
						toplabel
						clearable
						required
						:disabled="disabledDevices || loadingAnimation"
						v-model="selectedPollutant"
						:items="pollutantsOptions"
						item-text="name"
						item-value="elasticacronym"
						:hide-details="true"
						class="mt-2 pr-1"
						@input="dynamicInput"
					>
						<template v-slot:item="{ item }">
							<div class="v-list-item__content">
								<div class="v-list-item__title">{{ item.name }} {{ item.symbol ? '(' + item.symbol + ')' : '' }}</div>
							</div>
						</template>
						<template v-slot:selection="{ item }">
							<div class="v-select__selection v-select__selection--comma">
								{{ item.name }} {{ item.symbol ? '(' + item.symbol + ')' : '' }}
							</div>
						</template>
					</v-autocomplete>
				</v-col>
				<!--  -->
				<v-col cols="12" class="d-flex" sm="3">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						:disabled="!selectedPollutant || !selectedDevices.length > 0 || loadingAnimation"
						v-model="dateInterval"
						:items="intervals"
						:hide-details="true"
						@input="submitDateInterval"
						class="mt-2 pr-1"
					></v-select>
					<div class="d-flex" v-if="showChartOptions && option">
						<echart-show-interval
							v-if="pollutant && chartData && enableShowIntervalsDatasource"
							:elasticData="chartData"
							:designOption="designOption"
							:pollutant="pollutant"
							method="initChartDatasourceDevices"
							:showIntervals="showIntervals"
							@toggleShowIntervals="toggleShowIntervalsDatasource('initChartDatasourceDevices')"
							:height="42"
						></echart-show-interval>
						<echart-export-excel
							v-if="designOption && designOption.exportable == 1"
							:option="option"
							:designOption="overrideDesign"
							:pollutant="pollutant"
							:dateInterval="dateInterval"
							:bgColor="primaryColor"
							iconColor="white"
							:chartInstance="chartInstance"
							spacingClass="mt-4"
							:height="42"
							:advancedPropsObj="advancedProps"
							:zoomedProps="!isOriginalZoom ? ['initChartDatasourceDevices', pollutant, zoomedDocuments, designOption] : []"
						></echart-export-excel>
					</div>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" sm="6">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!startDateDisabled"
						v-model="startDate"
						:disabled="startDateDisabled || loadingAnimation"
						:max="Date.now()"
						:tooltipDescription="infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" sm="6">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!endDateDisabled"
						v-model="endDate"
						:disabled="endDateDisabled || loadingAnimation"
						:min="startDate"
						:max="Date.now()"
						:tooltipDescription="infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
			</v-row>
		</v-form>
		<!-- Metrics component -->
		<div row nowrap v-if="showChartOptions" class="d-flex justify-center mt-2 px-2">
			<v-chip v-if="metricAverage" class="fw-bold" style="margin-right: 5%" :ripple="false"
				>{{ $t('panels.notifications.average_operator') }}: {{ metricAverage }}</v-chip
			>
			<v-chip v-if="metricMin" class="fw-bold">Min: {{ metricMin }}</v-chip>
			<v-chip v-if="metricMax" class="fw-bold" style="margin-left: 5%">Max: {{ metricMax }}</v-chip>
		</div>
		<div v-else :style="fullscreenDialog ? 'height: 64px' : 'height: 32px'"></div>

		<div
			class="width-100 d-flex justify-center align-center px-3"
			:style="
				fullscreenDialog
					? showAdvancedDate
						? 'height: calc(100vh - 250px)'
						: 'height: calc(100vh - 230px)'
					: showAdvancedDate
						? 'height: calc(80vh - 125px - 48px - 32px)'
						: 'height: calc(80vh - 59px - 48px - 32px)'
			"
		>
			<!-- Loading animation -->
			<div class="d-flex justify-center align-center flex-column" v-if="loadingAnimation && !option" :style="chartStyle">
				<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
				<div class="mt-2" v-if="longLoading">{{ $t('common.long-loading') }}</div>
			</div>
			<v-chart
				v-if="option"
				:loading="loadingAnimation"
				:option="option"
				:style="chartStyle"
				autoresize
				ref="advancedDatasourceChartDevices"
				:class="'px-2 pb-3'"
				@datazoom="debouncedZoom"
			></v-chart>
		</div>
	</div>
</template>

<script>
import HistoricChartMixin from '@/mixins/HistoricChartMixin';
import { search, ChartOptions, obtainIndexName, sortKey, formatTimestamp } from '@/api/common';
import { /* lastData, */ scrollData } from '@/api/databases_API';
import { query_AdvancedDataByParameter } from '@/utils/queries';
import { debounce } from 'lodash';
import constants from '@/utils/constants';

import EchartExportExcel from './functionalities/EchartExportExcel.vue';
import EchartShowInterval from './functionalities/EchartShowInterval.vue';
import EchartShowIntervalMixin from './functionalities/EchartShowIntervalMixin';

import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

use([
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	ToolboxComponent,
	GridComponent,
	DataZoomComponent,
	VisualMapComponent,
	MarkLineComponent,
	MarkPointComponent,
	BarChart,
	LineChart,
	PieChart,
	CanvasRenderer
]);

//import logo_multireload from './logo_multireload.png';
export default {
	name: 'HistoricChartDatasource',
	mixins: [EchartShowIntervalMixin, HistoricChartMixin],
	components: {
		VChart,
		EchartExportExcel,
		EchartShowInterval
	},
	props: {
		pollutants: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			width: '100%',
			height: '100%',
			chart: null,
			pollutantNoSelected: true,
			xData: [],
			series: [],
			dateInterval: null,
			startDate: null,
			endDate: null,
			formDisabled: false,
			startDateDisabled: true,
			endDateDisabled: true,
			infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
			infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
			chartData: null,
			option: null,
			designOption: null,
			configurations: [],
			showChartOptions: false,
			showIntervals: false,
			selectedDatasource: null,
			datasourceOptions: [],
			selectedDevices: [],
			selectedPollutant: null,
			pollutantsOptions: [],

			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				{ text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 },
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],

			pollutantsForm: false,
			loadingAnimation: false,
			chartType: '',
			historicalChartData: [],
			chartStyle: {},
			parameterConfiguration: {},
			parameterOption: null,
			saving: false,
			showAdvancedDate: false,
			primaryColor: this.$store.state.global.primaryColor,
			chartInstance: null,
			advancedProps: null,
			selectAll: { code: -1, name: this.$t('panels.device.select-all') },
			selectNone: { code: -1, name: this.$t('panels.device.unselect-all') },
			devicesOptions: [],
			toggleMetrics: false,
			metricAverage: 0,
			metricMin: 0,
			metricMax: 0,
			disabledDevices: true,
			overrideDesign: {
				id: constants.VISUALIZATIONS.TIME_SERIES,
				name: 'COMPARE_DEVICES'
			},
			form: {},
			longLoading: false,
			timeoutId: null,
			timeoutLoading: 3000,
			zoomDebounce: 500,
			zoomedDocuments: [],
			isOriginalZoom: true,
			debouncedZoom: null
		};
	},
	computed: {
		devices() {
			if (this.$store.getters.getDevicesData && this.$store.getters.getDevicesData.length > 0) {
				let sortedDevices = this.$store.getters.getDevicesData;
				return sortedDevices; /* .sort((a, b) => {
					const [textA, numA] = sortKey(a.code);
					const [textB, numB] = sortKey(b.code);

					// First, compare the text parts
					if (textA < textB) return -1;
					if (textA > textB) return 1;

					// If the text parts are equal, compare the numeric parts
					return numA - numB;
				}); */
			}
			return [];
		},
		datasources() {
			if (this.$store.getters.getDatasourcetype && this.$store.getters.getDatasourcetype.length > 0) {
				let sortedDatasources = this.$store.getters.getDatasourcetype;
				return sortedDatasources; /* .sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				}); */
			}
			return [];
		},
		advancedDatasourceElasticData() {
			return this.$store.getters.getAdvancedDatasourceElasticData;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		historicFirstDates() {
			return this.$store.getters.getHistoricFirstDates;
		},
		historicLastDates() {
			return this.$store.getters.getHistoricLastDates;
		},
		pollutant() {
			return this.selectedPollutant ? this.pollutants.find((parameter) => parameter.elasticacronym == this.selectedPollutant) : null;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	},

	watch: {
		option: {
			handler(newVal) {
				if (newVal) {
					this.$nextTick(() => {
						if (this.$refs['advancedDatasourceChartDevices']) {
							this.chartInstance = this.$refs['advancedDatasourceChartDevices']; //.echartsInstance;
						} else {
							console.error('Chart instance is not available');
						}
					});
				}
			},
			immediate: true
		},
		zoomedDocuments(val) {
			console.log('zoomedDocuments watch', val, this.chartData.message, this.isOriginalZoom);
		}
	},
	created() {
		this.debouncedZoom = debounce(this.changedZoom, this.zoomDebounce);
	},
	mounted() {
		this.loadDesignOptions();

		if (this.datasources.length == 1) {
			this.datasourceOptions = this.datasources;
			this.selectedDatasource = this.datasources[0];
			this.disabledDevices = false;
		} else {
			this.devicesOptions = [this.selectAll].concat(this.devices);
		}
		if (this.pollutants.length == 1) this.selectedPollutant = [this.pollutants[0].elasticacronym];

		this.chartStyle = { height: '100%', width: '100%' };

		if (this.chartData && !this.chart) {
			this.showChartOptions = true;
			this.changeVisualization(this.designOption);
		}
	},
	beforeDestroy() {
		this.deleteChart();
		this.chartData = null;
	},
	methods: {
		deleteChart() {
			this.option = null;
			this.advancedProps = null;
		},
		visualizationConfiguration(visualization) {
			this.chartType = JSON.parse(visualization.configuration)['series'][0]['type'];
			let configuration = {
				text: visualization.visualizationname,
				value: {
					config: JSON.parse(visualization.configuration),
					id: visualization.acronym,
					applyranges: visualization.applyranges,
					exportable: visualization.exportable,
					showintervals: visualization.showintervals,
					name: visualization.visualizationname,
					description: visualization.description
				}
			};
			return configuration;
		},
		showDesignOptions() {
			this.showChartOptions = true;
			if (!this.configurations || !this.designOption) {
				const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
				const promise = search(historicalChartOptions);
				promise.then((historicalChartData) => {
					this.historicalChartData = historicalChartData.data.data;
					this.historicalChartData.forEach((visualization) => {
						let existConfiguration = false;
						if (this.configurations.length > 0) {
							this.configurations.forEach((configuration) => {
								if (configuration.text == visualization['visualizationname']) existConfiguration = true;
							});
						}
						if (!existConfiguration) {
							this.configurations.push({
								text: visualization.visualizationname,
								value: {
									config: JSON.parse(visualization.configuration),
									id: visualization.acronym,
									applyranges: visualization.applyranges,
									exportable: visualization.exportable,
									showintervals: visualization.showintervals,
									name: visualization.visualizationname,
									description: visualization.description
								}
							});
						}
					});
					this.designOption = this.configurations.find((option) => option.value.id == this.parameterOption.value.id).value;
					return this.configurations;
				});
			}
			return this.configurations;
		},
		changeVisualization(design, elasticData = null) {
			this.loadingAnimation = true;
			this.showIntervals = false;
			if (!design) {
				design = this.parameterOption.value;
			}
			if (typeof design.value == 'object') {
				this.chartType = design.value.config['series'].length > 0 ? design.value.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.value.config;
			} else {
				this.chartType = design.config['series'].length > 0 ? design.config['series'][0]['type'] : 'line';
				this.parameterConfiguration = design.config;
			}
			let designOption = this.designOption;
			if (this.historicalChartData.length > 0) {
				let visualization = this.historicalChartData.find((vis) => vis.acronym == design.id);
				if (visualization)
					designOption = {
						config: JSON.parse(visualization.configuration),
						id: visualization.acronym,
						applyranges: visualization.applyranges,
						exportable: visualization.exportable,
						showintervals: visualization.showintervals,
						name: visualization.visualizationname,
						description: visualization.description
					};
			}

			this.option = this.initChartDatasourceDevices(this.pollutant, elasticData ?? this.chartData, designOption, this.showIntervals);
			this.loadingAnimation = false;
		},
		submitDateInterval() {
			let d = new Date();
			if (this.dateInterval) {
				if (this.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.startDateDisabled = false;
					this.infoStartDate = this.$t('aqi.infoStartDate');
					this.infoEndDate = this.$t('aqi.infoEndDate');
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.startDateDisabled = true;
					this.startDate = this.endDate = null;
					this.infoStartDate = this.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					d.setDate(d.getDate() - this.dateInterval);
					this.chartData = null;
					let indexName = obtainIndexName(this.selectedDatasource);
					this.timeoutId = setTimeout(() => {
						this.longLoading = true;
					}, this.timeoutLoading);
					console.log(
						'submitDateInterval',
						this.selectedDevices,
						query_AdvancedDataByParameter(
							this.selectedDevices.map((device) => device.code),
							this.selectedPollutant,
							d.valueOf(),
							Date.now()
						)
					);
					scrollData(
						indexName,
						query_AdvancedDataByParameter(
							this.selectedDevices.map((device) => device.code),
							this.selectedPollutant,
							d.valueOf(),
							Date.now()
						)
					).then((elasticData) => {
						clearTimeout(this.timeoutId);
						this.longLoading = false;
						if (elasticData.message.length > 0) {
							this.chartData = elasticData;
							this.showDesignOptions();
							this.changeVisualization(this.designOption, elasticData);
						} else {
							this.loadingAnimation = false;
							this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
							this.showChartOptions = false;
							this.deleteChart();
							this.chartData = null;
						}
					});
				}
			} else {
				this.showAdvancedDate = false;
				this.startDateDisabled = true;
				this.endDateDisabled = true;
				this.startDate = this.endDate = null;
				this.infoStartDate = this.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.deleteChart();
				this.chartData = null;
				this.showChartOptions = false;
				this.loadingAnimation = false;
			}
		},
		inputStartDate() {
			if (this.startDate) {
				this.endDateDisabled = false;
				if (this.endDate) this.submitEndDate();
			} else {
				this.endDateDisabled = true;
			}
		},
		submitEndDate() {
			if (this.startDate && this.endDate) {
				this.loadingAnimation = true;
				this.chartData = null;
				let startDate = new Date(this.startDate);
				let endDate = new Date(this.endDate);
				let endPlusOne = endDate.getUTCDate() + 1;
				endDate.setUTCDate(endPlusOne);
				console.log('startDate debug', startDate, endDate, startDate.valueOf(), endDate.valueOf());
				this.timeoutId = setTimeout(() => {
					this.longLoading = true;
				}, this.timeoutLoading);
				scrollData(
					obtainIndexName(this.selectedDatasource),
					query_AdvancedDataByParameter(this.selectedDevices, this.selectedPollutant, startDate.valueOf(), endDate.valueOf())
				).then((elasticData) => {
					clearTimeout(this.timeoutId);
					this.longLoading = false;
					if (elasticData.message.length > 0) {
						this.chartData = elasticData;
						this.showDesignOptions();
						this.changeVisualization(this.designOption, elasticData);
					} else {
						this.loadingAnimation = false;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
						this.showChartOptions = false;
						this.deleteChart();
						this.chartData = null;
					}
				});
			}
		},

		async loadDesignOptions() {
			const historicalChartOptions = ChartOptions(constants.VISUALIZATION_TYPE_HISTORIC);
			const { data } = await search(historicalChartOptions);
			this.configurations = [];
			this.historicalChartData = data.data;
			let vis = this.historicalChartData.find((vis) => vis.acronym == constants.VISUALIZATIONS.TIME_SERIES);

			this.parameterOption = this.visualizationConfiguration(vis);
			this.parameterConfiguration = this.parameterOption.value.config;
			this.designOption = this.parameterOption.value;
			this.configurations = [vis];

			return this.configurations;
		},
		dynamicInput(e) {
			console.log('dynamic input', e, this.selectedDatasource, this.selectedDevices, this.selectedPollutant);
			/* if (Array.isArray(e)) {
				if (e[e.length - 1] == -1) {
					this.selectedDevices = [];
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
					this.devices.forEach((device) => this.selectedDevices.push(device.code));
				} else if (e[e.length - 1] == -2) {
					this.selectAll.name = this.$t('panels.device.select-all');
					this.selectAll.code = -1;
					this.selectedDevices = [];
				} else if (e.length == this.devices.length) {
					this.selectAll.name = this.$t('panels.device.unselect-all');
					this.selectAll.code = -2;
				} else if (e.includes(-1)) {
					const index = this.selectedDevices.indexOf(-1);
					if (index > -1) {
						this.selectedDevices.splice(index, 1);
					}
				}
			} */
			if (this.selectedPollutant && this.selectedDevices.length > 0 && this.dateInterval) {
				if (this.dateInterval === -1) {
					if (this.startDate && this.endDate) this.submitEndDate();
				} else this.submitDateInterval();
			} else {
				this.deleteChart();
				this.chartData = null;
			}
		},
		inputDatasource(datasourcetype) {
			console.log('inputDatasource', datasourcetype, this.selectedDatasource);
			if (datasourcetype) {
				this.devicesOptions = [];
				this.selectedDevices = [];
				this.selectedPollutant = null;
				this.disabledDevices = false;

				/* this.devicesOptions = this.devices.filter((device) => device.pmdatasourcetypeid == this.selectedDatasource[0].pmdatasourcetypeid);
				this.selectedDevices = [this.devicesOptions[0].code]; */
				this.devicesOptions = [];
				this.pollutantsOptions = [];

				this.pollutantsOptions = this.pollutants.filter((pollutant) => pollutant.pmdatasourcetypeid == datasourcetype.pmdatasourcetypeid);
				this.devicesOptions = this.devices.filter((device) => device.pmdatasourcetypeid == datasourcetype.pmdatasourcetypeid);

				this.devicesOptions.sort((a, b) => {
					const [textA, numA] = sortKey(a.code);
					const [textB, numB] = sortKey(b.code);

					// First, compare the text parts
					if (textA < textB) return -1;
					if (textA > textB) return 1;

					// If the text parts are equal, compare the numeric parts
					return numA - numB;
				});
				this.pollutantsOptions.sort((a, b) => {
					if (a.name < b.name) return -1;
					if (a.name > b.name) return 1;
					return 0;
				});
				console.log('inputDatasource 2', this.devicesOptions, this.pollutantsOptions);
			} else {
				this.devicesOptions = [];
				this.selectedDevices = [];
				this.selectedPollutant = null;
				this.disabledDevices = true;
			}
		},
		changedZoom() {
			let option = this.chartInstance.getOption();
			let minValue = 0;
			let maxValue = 0;
			let minValueDevice = this.selectedDevices[0].code;
			let maxValueDevice = this.selectedDevices[0].code;
			let minValueDate = '';
			let maxValueDate = '';
			let globalSum = 0;
			let globalCount = 0;
			let pollutantSymbol = this.pollutant.symbol;

			if (this.chartData) {
				let documents = this.chartData.message.map((hit) => hit._source);
				let filteredDocs = documents.filter(
					(doc) =>
						doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP] >= option.dataZoom[0].startValue &&
						doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP] <= option.dataZoom[0].endValue &&
						doc[this.selectedPollutant] >= option.dataZoom[1].startValue &&
						doc[this.selectedPollutant] <= option.dataZoom[1].endValue
				);
				this.zoomedDocuments = filteredDocs;
				this.isOriginalZoom = filteredDocs.length == documents.length;
				if (filteredDocs.length > 0) {
					globalCount = filteredDocs.length;
					minValue = filteredDocs[0][this.selectedPollutant];
					filteredDocs.forEach((doc) => {
						if (doc[this.selectedPollutant] < minValue) {
							minValue = doc[this.selectedPollutant];
							minValueDevice = doc.device;
							minValueDate = doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
						}
						if (doc[this.selectedPollutant] > maxValue) {
							maxValue = doc[this.selectedPollutant];
							maxValueDevice = doc.device;
							maxValueDate = doc[constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
						}
						globalSum += doc[this.selectedPollutant];
					});
					this.metricAverage =
						(globalSum > 0
							? parseInt(globalSum / globalCount) === globalSum / globalCount
								? globalSum / globalCount
								: (globalSum / globalCount).toFixed(2)
							: '-') + (pollutantSymbol || '');

					this.metricMin =
						minValue.toFixed(2) +
						(pollutantSymbol || '') +
						' (' +
						minValueDevice +
						') - ' +
						formatTimestamp(minValueDate, this.userProperties);

					this.metricMax =
						maxValue.toFixed(2) +
						(pollutantSymbol || '') +
						' (' +
						maxValueDevice +
						') - ' +
						formatTimestamp(maxValueDate, this.userProperties);

					console.log('datazoom metrics', this.metricAverage, this.metricMax, this.metricMin);
				} else {
					this.metricAverage = this.metricMax = this.metricMin = '-';
				}
			}
		},
		icon(selected, total) {
			if (selected == total) return 'fas fa-check-square';
			if (selected > 0 && selected < total) return 'fas fa-minus-square';
			return 'far fa-square';
		},
		toggleSelection(selectedProperty, optionsProperty, allSelected) {
			this.$nextTick(() => {
				if (allSelected) {
					this[selectedProperty] = [];
				} else {
					this[selectedProperty] = this[optionsProperty].slice();
				}
			});
		},
		testEvt(e) {
			console.log('export excel', e);
			alert('Exporting excel', e);
		}
	}
};
</script>

<style lang="postcss">
.historic-datasource-chart {
	& .chart-historic {
		height: calc(80vh - 187px);
		width: 85vw;
	}
	& #intervals-btn {
		background-color: var(--gris-50);
		border-radius: 8px;
	}
	& #intervals-btn.active {
		color: var(--azul-primary-100) !important;
		background-color: var(--azul-primary-100) !important;
	}
	.v-input__prepend-inner {
		padding-top: 3px;
		padding-left: 4px;
		& .v-icon {
			font-size: 18px;
			font-weight: 500;
		}
	}
}
/* Global CSS for historic and dashboard */
.historic-datasource-chart {
	& .vue-echarts-inner > div:last-child > div:last-child > div[style*='cursor: pointer'] {
		background-color: var(--azul-primary-100) !important;
		color: white;
		font-size: 14px !important;
		padding: 6px 12px !important;
	}
}
.data-view-table td {
	border: 1px solid black;
}
</style>
