<template>
	<v-dialog v-model="dialog" persistent :fullscreen="fullscreenDialog" max-width="85vw" class="card feature-dialog">
		<slot name="header">
			<header class="card__header overflow-x-auto py-2 pl-2">
				<v-row no-gutters class="">
					<v-col cols="8" class="d-flex flex-nowrap align-center pa-0">
						<span class="station-code color-white fw-bold pa-0 pl-2">Compare by datasource</span>
					</v-col>
					<v-col cols="4" class="pa-0 justify-end">
						<slot name="action-button" class="align-center justify-end card__button"></slot>
					</v-col>
				</v-row>
			</header>
		</slot>
		<div class="card-content-container" id="dialog-container-datasource">
			<datasource-panel />
		</div>
	</v-dialog>
</template>

<script>
import constants from '@/utils/constants';
import DatasourcePanel from './device/DatasourceIndex.vue';
//import { CARD_CONTAINER_HEIGHT, CARD_CONTAINER_HEIGHT_DIALOG } from '@/lib/variables/panels';

export default {
	components: { DatasourcePanel },
	props: {
		dialog: {
			default: false
		}
	},
	data() {
		return {
			tab: null,
			device: constants.FEATURE_TYPE_DEVICE,
			notification: constants.FEATURE_TYPE_NOTIFICATION,
			satelliteModel: constants.SATELLITE_MODEL,
			/* cardContainerStyle: {
				height: CARD_CONTAINER_HEIGHT
			},
			dialogContainerStyle: {
				height: '60vh'
			}, */
			deviceKey: 1,
			notificationKey: 2,
			displayLocation: 'dialog',
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		bodyStyle() {
			return {
				//'flex-direction': this.vertical ? 'column' : 'row',
				'padding-top': this.title ? '0px' : '16px'
			};
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		fullscreenDialog: {
			get() {
				return this.$store.getters.getFullscreenDialog;
			},
			set(value) {
				this.$store.commit('setFullscreenDialog', value);
			}
		}
	},
	beforeMount() {},
	mounted() {
		console.warn('debug', document.getElementsByClassName('v-dialog'), 'type', this.type);

		/* if (document.getElementsByClassName('v-dialog').length > 0) {
			console.log('debug 2', document.getElementById('feature-dialog'), document.getElementsByClassName('v-dialog'));
			document.getElementsByClassName('v-dialog')[0].id = 'feature-dialog-element';
		} */
	},
	updated() {
		console.warn('updated');
	},
	methods: {}
};
</script>

<style lang="postcss" scoped>
.card {
	position: absolute;
	bottom: 1.1rem;
	z-index: 2;
	border: 1px solid var(--moderate);
	background: white;
	width: 98%;
	right: 1%;

	&__body {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		padding: 0 1.125em 0.625em 1.125em;
		height: 330px;
		overflow: hidden;
	}

	&__header {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		padding: 1.125em 1.125em 0.5em 1.125em;
		min-height: 42px;
		background-color: var(--azul-primary-100);
		& .station-code {
			font-size: 24px !important;
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: start;
		align-items: center;
	}

	&__title {
		color: #414141;
		font-size: 1.125rem;
	}

	&__button:hover {
		background: var(--primarycolor);

		& i {
			color: white;
		}
	}
}

#dialog-container-datasource {
	//transition: all 1s ease-out;
	height: var(--datasource-height-dialog) !important;
}

.card-content-tabs {
	//transition: max-height 1s ease-out;
	max-height: 50px;
}

label.v-label.theme--light {
	left: 8px !important;
	top: 5px !important;
}
label.v-label.v-label--active {
	left: 0px !important;
	top: -1px !important;
}
.v-select__selection--comma {
	margin-left: 7px !important;
}

.v-dialog--active.full-screen {
	height: 100vh !important;
	width: 100vw !important;
}
#feature-dialog-element {
	overflow: hidden;
}
</style>
